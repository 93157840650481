// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10867(11a97c89fd)-C06/11/2024-16:55:01-B06/11/2024-17:09:06' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10867(11a97c89fd)-C06/11/2024-16:55:01-B06/11/2024-17:09:06",
  branch: "master",
  latestTag: "6.1",
  revCount: "10867",
  shortHash: "11a97c89fd",
  longHash: "11a97c89fd6c7ee01abde979bba8cd172c6e259c",
  dateCommit: "06/11/2024-16:55:01",
  dateBuild: "06/11/2024-17:09:06",
  buildType: "Ansible",
  } ;
